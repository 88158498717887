:root {
  --primary: #A3C6C4;
  --secondary: #D8A0C0;
  --tertiary: #FFF9F8;
  --white: #FFFFFF;
  --gray: #c2c2c2;
}

:root, html, body {
  background-color: var(--tertiary);
}

.background-pink {
  background-color: var(--tertiary) !important;
}

.footer {
  color: var(--gray);
  font-size: 0.8rem;
  text-align: center;
  padding: 1rem;
}

.markdown {
  margin: 0;
  font-family: "Roboto","Helvetica","Arial",sans-serif;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.43;
  letter-spacing: 0.01071em;
}
.markdown ul {
  margin-block-start: 0.1rem;
  margin-block-end: 0.1rem;
  padding-inline-start: 1rem;
}
.markdown h1, .markdown h2, .markdown h3, .markdown h4, .markdown h5, .markdown h6 {
  line-height: 1.167;
  letter-spacing: 0em;
  margin-bottom: 0.3rem;
}

table {
  width: 100%;
}
tr {
  width: 100%;
}
tr td:first-child {
  font-weight: bold;
  text-align: right;
  padding-right: 0.5rem;
  width: 1%;
  white-space: nowrap;
}
tr td {
  text-align: left;
  padding-left: 0.5rem;
  width: max-content;
}

table.dosage {
  border-collapse: collapse;
}
table.dosage tr {
  border-bottom: 1px solid var(--gray);
}
table.dosage tr:last-child {
  border-bottom: none;
}
